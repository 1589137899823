import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { IMPORT_FILE } from '@/store/modules/cart/actionTypes'
import { computed, ref } from 'vue'

export default (onDone = () => {}) => {
  const store = useStore()
  const toast = useToast()

  const showModal = ref(false)
  const modalCallback = ref(() => { })
  
  const cartItemsCount = computed(() => store.getters.getCartItemsCount)

  const importFile = (event, clear = true) => {
    const [file] = event.target.files
    if (file) {
      store.dispatch(IMPORT_FILE, { file, clear }).then(() => {
        toast.success(clear ? 'Товары в корзине заменены' : 'Товары в корзине объединены')
        onDone()
      }).catch((error) => {
        if (error?.response?.status === 422) {
          onDone()
        } else {
          toast.error('Не удалось импортировать файл')
        }
      }).finally(() => {
        event.target.value = null
      })
    }
  }
  const importToCart = (event) => {
    if (cartItemsCount.value > 0) {
      modalCallback.value = (clear = true) => importFile(event, clear)
      showModal.value = true
    } else {
      importFile(event)
    }
  }
  return {
    showModal,
    modalCallback,
    importToCart
  }
}