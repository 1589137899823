<template>
  <div class="table cart__table">
    <div class="caption" :class="captionClass">
      <div class="caption__inner">
        <div class="caption__heading">
          <template v-if="suborder.contract_name">
            Договор {{ suborder.contract_name }}
            от {{ suborder.contract_date }} г.
          </template>
        </div>
        <div class="caption__status">
          <svg class="s-icon s-icon--md caption__icon"><use :xlink:href="`/img/svg/sprite1.svg#${icon}`" /></svg>
          <div class="caption__text">
            {{ title }}
          </div>
        </div>
      </div>
    </div>
    <div class="table__head">
      <div class="table__th table__th--first">
        Наименование / Штрих-код
      </div>
      <div class="table__th table__th--second">
        Цена
      </div>
      <div class="table__th table__th--fourth">
        Количество
      </div>
      <div class="table__th table__th--third">
        Итого
      </div>
    </div>
    <template v-if="isCheckout">
      <suborder-item v-for="item in suborder.items" :key="item.product.id" :item="item" />
    </template>
    <template v-else>
      <cart-suborder-item v-for="item in suborder.items" :key="item.product.id" :item="item" />
    </template>
  </div>
</template>

<script>
import CartSuborderItem from '@/components/cart/CartSuborderItem'
import SuborderItem from '@/components/orders/SuborderItem'
import { getStatusCaptionClass, getStatusIcon, getStatusTitle } from '@/common/prepaymentStatus'
import { computed } from 'vue'


export default {
  components: {
    CartSuborderItem,
    SuborderItem
  },
  props: {
    isCheckout: {
      type: Boolean,
      default: false
    },
    suborder: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const captionClass = computed(() => getStatusCaptionClass(props.suborder.prepayment_status))
    const icon = computed(() => getStatusIcon(props.suborder.prepayment_status))
    const title = computed(() => getStatusTitle(props.suborder.prepayment_status))
    return {
      captionClass,
      icon,
      title
    }
  }
}
</script>
