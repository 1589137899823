<template>
  <section class="">
    <div class="page-header">
      <div class="container">
        <div class="page-header__inner">
          <div class="page-header__left">
            <h1>Корзина</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="cart">
      <div class="container">
        <div class="cart__inner">
          <cart-suborder-placeholder v-for="index in placeholderCount" :key="index" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CartSuborderPlaceholder from './CartSuborderPlaceholder.vue'

export default {
  components: {
    CartSuborderPlaceholder
  },
  props: {
    placeholderCount: {
      type: Number,
      default: 2
    }
  }
}
</script>
