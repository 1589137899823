import { computed } from 'vue'

export default (product, includeVatAmount = true) => {
  const barcodes = computed(() => Array.isArray(product.barcodes) ? product.barcodes.join(', ') : null)
  const vat = computed(() => {
    const vatAmount = includeVatAmount ? ` ${product.vat}%` : ''
    return product.vat ? `в т.ч. НДС${vatAmount}` : 'без НДС'
  })
  return {
    barcodes,
    vat
  }
}