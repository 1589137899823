<template>
  <div class="table cart__table">
    <div class="caption caption--gray">
      <div class="caption__inner">
        <div class="caption__heading">
          &nbsp;
        </div>
      </div>
    </div>
    <div class="table__head">
      <div class="table__th table__th--first">
        Наименование / Штрих-код
      </div>
      <div class="table__th table__th--second">
        Цена
      </div>
      <div class="table__th table__th--fourth">
        Количество
      </div>
      <div class="table__th table__th--third">
        Итого
      </div>
    </div>
    <cart-suborder-item-placeholder v-for="index in placeholderCount" :key="index" />
  </div>
</template>

<script>
import CartSuborderItemPlaceholder from './CartSuborderItemPlaceholder.vue'

export default {
  components: {
    CartSuborderItemPlaceholder
  },
  props: {
    placeholderCount: {
      type: Number,
      default: 5
    }
  }
}
</script>
