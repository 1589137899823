import ToastIcon from '@/components/catalog/ToastIcon'
import mapResponseErrors from '@/common/mapResponseErrors'
import { formatCurrency } from '@/common/formatNumbers'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { ADD_CART_ITEM, UPDATE_CART_ITEM, REMOVE_CART_ITEM } from '@/store/modules/cart/actionTypes'
import { SET_PRODUCT_CART_QUANTITY, SET_PRODUCT_CART_FORMAT } from '@/store/modules/cart/mutationTypes'
import { ORDER_FORMAT_PIECE, ORDER_FORMAT_PACKAGE } from '@/common/orderFormats'
import { useToast } from 'vue-toastification'

export default (product) => {
  const store = useStore()
  const toast = useToast()
  const productInCart = computed(() => store.getters.getCartProducts[product.id])

  const quantity = computed({
    get() {
      return productInCart.value?.quantity
    },
    set(newQuantity) {
      store.commit(SET_PRODUCT_CART_QUANTITY, { id: product.id, quantity: newQuantity })
    }
  })
  const format = computed({
    get() {
      return productInCart.value?.format
    },
    set(newFormat) {
      store.commit(SET_PRODUCT_CART_FORMAT, { id: product.id, format: newFormat })
    }
  })
  const cartError = ref(null)
  const price = computed(() => formatCurrency(format.value === ORDER_FORMAT_PACKAGE ? product.package_price : product.price))

  const parseCartError = (error, message) => {
    if (error?.response?.status === 422) {
      const errors = mapResponseErrors(error.response)
      cartError.value = Object.values(errors).shift()
    } else {
      toast.error(message)
    }
  }

  const addToCart = () => {
    const format = product.price ? ORDER_FORMAT_PIECE : ORDER_FORMAT_PACKAGE
    store.dispatch(ADD_CART_ITEM, { item: { product_id: product.id, format, quantity: 1 }, params: { expand: 'suborders' } }).then(() => {
      toast.success("Товар добавлен в корзину", {
        icon: ToastIcon
      })
      cartError.value = null
    }).catch((error) => {
      parseCartError(error, 'Не удалось добавить товар в корзину')
    })
  }
  const updateCartItem = () =>
    store.dispatch(UPDATE_CART_ITEM, { id: product.id, params: { expand: 'suborders' } }).then(() => {
      cartError.value = null
    }).catch((error) => {
      parseCartError(error, 'Не удалось обновить корзину')
    })
  const removeCartItem = () => store.dispatch(REMOVE_CART_ITEM, { id: product.id, params: { expand: 'suborders' } }).then(() => {
    cartError.value = null
  }).then(() => {
    toast.success("Товар удален из корзины", {
      icon: ToastIcon
    })
    cartError.value = null
  }).catch((error) => {
    parseCartError(error, 'Не удалось удалить товар из корзины')
  })
  return {
    productInCart,
    cartError,
    price,
    quantity,
    format,
    addToCart,
    updateCartItem,
    removeCartItem
  }
}