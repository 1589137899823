<template>
  <div v-if="notFoundBarcodes.length" class="search-failed">
    <div class="container">
      <div class="search-failed__inner">
        <p class="message-info search-failed__info">
          <span class="message-info__icon">
            <svg class="s-icon">
              <use xlink:href="/img/svg/sprite1.svg#warning" />
            </svg>
          </span>
          <span class="message-info__text">Мы не смогли найти следующие штрих-коды при импорте</span>
        </p>
        <div class="search-failed__row">
          <div class="search-failed__codes">
            <ul class="search-failed__list">
              <li v-for="(barcode, index) in notFoundBarcodes" :key="index" class="search-failed__item">
                {{ barcode }}
              </li>
            </ul>
            <button v-if="hasMore" class="search-failed__btn" @click="showAll = true">
              Показать все
            </button>
          </div>
          <label class="k-file">
            <input class="k-file__input" type="file" @change="importToCart">
            <span class="k-file__box btn btn--secondary">
              <span class="k-file__icon">
                <svg class="s-icon">
                  <use xlink:href="/img/svg/sprite1.svg#upload" />
                </svg>
              </span>
              Перезагрузить файл
            </span>
          </label>
        </div>
      </div>
    </div>
    <cart-modal v-model="showModal" modal-title="Импорт товаров" :modal-callback="modalCallback" />
  </div>
</template>

<script>
import CartModal from '../CartModal.vue'
import useCartImport from '@/composables/useCartImport'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { FETCH_CART } from '@/store/modules/cart/actionTypes'
import { useToast } from 'vue-toastification'

const DEFAULT_LIST_SIZE = 5

export default {
  components: { CartModal },
  setup() {
    const store = useStore()
    const toast = useToast()

    const showAll = ref(false)

    const allNotFoundBarcodes = computed(() => store.getters.getNotFoundBarcodes || [])
    const notFoundBarcodes = computed(() => {
      return showAll.value ? allNotFoundBarcodes.value : allNotFoundBarcodes.value.slice(0, DEFAULT_LIST_SIZE)
    })
    const hasMore = computed(() => notFoundBarcodes.value.length < allNotFoundBarcodes.value.length)

    const { importToCart, modalCallback, showModal } = useCartImport(() => store.dispatch(FETCH_CART, { expand: 'suborders' }).catch(() => {
      toast.error('Не удалось загрузить корзину')
    }))

    return {
      modalCallback,
      showModal,
      hasMore,
      importToCart,
      showAll,
      notFoundBarcodes
    }
  }
}
</script>
