export const ORDER_FORMAT_PIECE = 0
export const ORDER_FORMAT_PACKAGE = 1

export const formats = {
  [ORDER_FORMAT_PIECE]: 'шт.',
  [ORDER_FORMAT_PACKAGE]: 'уп.'
}

export const getFormatDetailsTitle = (format, packageQuantity) => {
  return `за ${format === ORDER_FORMAT_PIECE ? 'шт.' : `уп. ${packageQuantity} шт.`}`
}
