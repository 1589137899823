<template>
  <vue-final-modal v-model="showModal" @closed="showModal = false">
    <div class="modal-import active" data-simplebar>
      <div class="modal-import__overlay" />
      <div class="modal-import__wrapper">
        <div class="modal-import__inner">
          <button class="modal-import__close" @click="showModal = false">
            <svg class="s-icon s-icon--largest">
              <use xlink:href="/img/svg/sprite1.svg#cancel" />
            </svg>
          </button>
          <div class="modal-import__title">
            {{ modalTitle }}
          </div>

          <div class="modal-import__text">
            <p>
              В корзине есть товары, которые вы добавили ранее. <br>
              Вы хотите объединить их с новыми?
            </p>
          </div>

          <div class="modal-import__footer">
            <button class="modal-import__btn btn btn--primary btn--small" @click="modalCallback(false)">
              Объединить
            </button>
            <button class="modal-import__btn btn btn--secondary btn--small" @click="modalCallback(true)">
              Заменить
            </button>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { computed } from 'vue'
import { VueFinalModal } from 'vue-final-modal'

export default {
  components: {
    VueFinalModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    modalCallback: {
      type: Function,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const showModal = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })
    return {
      showModal
    }
  }
}
</script>

<style scoped>

</style>