<template>
  <div class="order-form">
    <div class="container">
      <div class="order-form__inner">
        <form class="order-form__form" @submit.prevent>
          <div class="field order-form__select" :class="{ 'v-error': form.errors.shipping_address_id }">
            <span class="field__suptitle">Адрес доставки</span>
            <vue-multiselect
              id="address"
              v-model="selectedAddress"
              :disabled="isSubmitting"
              :options="addresses"
              :multiple="false"
              :searchable="true"
              placeholder="Введите адрес"
              track-by="id"
              :show-labels="false"
              :allow-empty="false"
              :loading="isAddressesLoading"
              :internal-search="false"
              @update:model-value="onAddressChanged"
              @search-change="fetchAddressesDebounced"
            >
              <template #noOptions>
                Список адресов пуст
              </template>
              <template #singleLabel="{ option }">
                <template v-if="option.name !== option.address">
                  <div class="select__subtitle">
                    {{ option.name }}
                  </div>
                  <div class="select__text">
                    {{ option.address }}
                  </div>
                </template>
                <div v-else class="select__subtitle">
                  {{ option.address ? option.address : option.name }}
                </div>
                <span v-if="form.errors.shipping_address_id" class="field__icon" :data-r-prompt="form.errors.shipping_address_id">
                  <svg class="s-icon">
                    <use xlink:href="/img/svg/sprite1.svg#warning" />
                  </svg>
                </span>
              </template>
              <template #option="{ option }">
                <template v-if="option.name !== option.address">
                  <div class="select__subtitle">
                    {{ option.name }}
                  </div>
                  <div class="select__text">
                    {{ option.address }}
                  </div>
                </template>
                <div v-else class="select__subtitle">
                  {{ option.address ? option.address : option.name }}
                </div>
              </template>
              <template #noResult>
                Ничего не найдено.
              </template>
            </vue-multiselect>
          </div>
          <div class="order-form__heading">
            Кто примет заказ
          </div>
          <div class="order-form__row">
            <label class="field field--icon order-form__field order-form__field--name" :class="{ 'v-error': form.errors.recipient_name }">
              <span class="field__suptitle visually-hidden">Имя и Фамилия сотрудника</span>
              <input
                v-model="form.body.recipient_name"
                :disabled="isSubmitting"
                type="text"
                class="field__input"
                placeholder="Имя и Фамилия сотрудника"
              >
              <span v-if="form.errors.recipient_name" class="field__icon" :data-r-prompt="form.errors.recipient_name">
                <svg class="s-icon">
                  <use xlink:href="/img/svg/sprite1.svg#warning" />
                </svg>
              </span>
            </label>
            <label class="field field--icon order-form__field order-form__field--phone" :class="{ 'v-error': form.errors.recipient_phone }">
              <span class="field__suptitle visually-hidden">Телефон сотрудника</span>
              <input
                v-model="form.body.recipient_phone"
                v-input-mask="`+7 (999) 999-99-99`"
                :disabled="isSubmitting"
                type="tel"
                class="field__input"
                placeholder="Телефон сотрудника"
                inputmode="text"
              >
              <span v-if="form.errors.recipient_phone" class="field__icon" :data-r-prompt="form.errors.recipient_phone">
                <svg class="s-icon">
                  <use xlink:href="/img/svg/sprite1.svg#warning" />
                </svg>
              </span>
            </label>
          </div>
          <label class="field" :class="{ 'v-error': form.errors.comment }">
            <span class="field__suptitle">Комментарий к заказу</span>
            <textarea
              v-model="form.body.comment"
              :disabled="isSubmitting"
              class="field__textarea"
              placeholder="Необязательно"
            />
            <span v-if="form.errors.comment" class="field__icon" :data-r-prompt="form.errors.comment">
              <svg class="s-icon">
                <use xlink:href="/img/svg/sprite1.svg#warning" />
              </svg>
            </span>
          </label>
          <div class="order-form__footer">
            <div class="order-form__total">
              <div class="order-form__suptitle">
                Итого
              </div>
              <div class="order-form__value">
                {{ total }}
              </div>
              <div v-if="vatAmount" class="order-form__subtitle">
                {{ vatAmount ? vatAmount : 'без' }} НДС
              </div>
            </div>
            <button :disabled="isSubmitting" class="btn btn--primary order-form__submit" @click.prevent="checkout">
              Подтвердить заказ
            </button>
          </div>
        </form>

        <div class="order-form__info">
          <div class="order-form__info-block">
            <svg class="s-icon">
              <use xlink:href="/img/svg/sprite1.svg#info" />
            </svg>
            <span>Для добавления нового адреса доставки отправьте запрос на <a href="mailto:zakaz@dsf.kz">zakaz@dsf.kz</a></span>
          </div>
          <div class="order-form__info-block">
            <svg class="s-icon">
              <use xlink:href="/img/svg/sprite1.svg#info" />
            </svg>
            <span>Для приемки заказа потребуется печать или доверенность.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import debounce from 'lodash/debounce'
import { catchFormError } from '@/common/catchFormError'
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useApi } from '@/api'
import { CHECKOUT } from '@/store/modules/cart/actionTypes'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export default {
  components: {
    VueMultiselect
  },
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    },
    total: {
      type: String,
      required: true
    },
    vatAmount: {
      type: String,
      default: null,
      required: false
    }
  },
  setup () {
    const selectedAddress = ref(null)
    const isAddressesLoading = ref(false)
    const addresses = ref([])
    const api = useApi()
    const fetchAddresses = (query) => {
      isAddressesLoading.value = true
      api.addresses.search({ query }).then(response => {
        addresses.value = response.data
      }).finally(() => {
        isAddressesLoading.value = false
      })
    }
    const fetchAddressesDebounced = debounce(fetchAddresses, process.env.VUE_APP_DEBOUNCE_TIME)
    onMounted(() => fetchAddresses())
    const store = useStore()
    const form = reactive({
      body: {
        recipient_name: '',
        recipient_phone: '',
        comment: '',
        shipping_address_id: '',
      },
      errors: {}
    })
    const router = useRouter()
    const toast = useToast()
    const checkout = () => {
      store.dispatch(CHECKOUT, { form: form.body, params: { expand: 'suborders.items,shipping_address' } }).then(() => {
        router.push('/checkout-success')
      }).catch(catchFormError(form, toast))
    }
    const onAddressChanged = (address) => {
      form.body.shipping_address_id = address.id
    }
    return {
      selectedAddress,
      onAddressChanged,
      checkout,
      form,
      isAddressesLoading,
      fetchAddressesDebounced,
      addresses
    }
  }
}
</script>
