<template>
  <cart-placeholder v-if="showPlaceholders" />
  <section v-else class="cart-section">
    <div v-if="cart" class="page-header">
      <div class="container">
        <div class="page-header__inner">
          <div class="page-header__left">
            <h1>Корзина</h1>
            <div class="page-header__quantity">
              {{ cartItemsCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <cart-import />
    <div class="cart">
      <div class="container">
        <div v-if="cart" class="cart__inner">
          <cart-suborder v-for="(suborder, index) in cart.suborders" :key="index" :suborder="suborder" />
        </div>
        <div v-else class="cart__inner">
          <p class="empty-cart-message" style="background-image: url(/img/cart/ic_cart.svg)">
            <span class="empty-cart-title">Корзина пуста</span>
            <router-link class="btn btn--primary btn--large empty-cart-btn" to="/">
              Перейти в каталог
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <checkout-form
      v-if="cart"
      :total="cartTotalPrice"
      :vat-amount="cartVatAmount"
      :is-submitting="isCartSubmitting"
    />
  </section>
</template>

<script>
import CartPlaceholder from '@/components/placeholders/CartPlaceholder.vue'
import CartImport from '@/components/cart/CartImport.vue'
import CartSuborder from '@/components/cart/CartSuborder'
import CheckoutForm from '@/components/cart/CheckoutForm'
import useCartState from '@/composables/useCartState'
import { onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { FETCH_CART } from '@/store/modules/cart/actionTypes'
import { SET_NOT_FOUND_BARCODES } from '@/store/modules/cart/mutationTypes'
import { useToast } from 'vue-toastification'

export default {
  components: {
    CartPlaceholder,
    CartImport,
    CartSuborder,
    CheckoutForm
  },
  setup() {
    const showPlaceholders = ref(true)
    const store = useStore()
    const toast = useToast()
    store.dispatch(FETCH_CART, { expand: 'suborders' }).catch(() => {
      toast.error('Не удалось загрузить корзину')
    }).finally(() => {
      showPlaceholders.value = false
    })
    onUnmounted(() => {
      store.commit(SET_NOT_FOUND_BARCODES, null)
    })
    const { cart, cartTotalPrice, cartVatAmount, cartItemsCount, isCartSubmitting } = useCartState()
    return {
      showPlaceholders,
      cart,
      cartItemsCount,
      cartTotalPrice,
      cartVatAmount,
      isCartSubmitting
    }
  }
}
</script>