<template>
  <div class="table__list">
    <div class="table__col table__col--first">
      <div class="table__col-first">
        <div v-if="item.product.image" class="table__image">
          <img :src="item.product.image" :alt="item.product.name">
        </div>
        <div class="table__content">
          <div class="table__name">
            {{ item.product.name }}
          </div>
          <div class="table__signature">
            {{ barcodes }}
          </div>
        </div>
      </div>
    </div>
    <div class="table__col table__col--second active">
      <div v-if="price" class="table__col-second">
        <div class="table__name">
          {{ price }}
        </div>
        <div class="table__signature">
          {{ formatDetailsTitle }}
        </div>
      </div>
    </div>
    <div class="table__col table__col--fourth">
      <div v-if="price" class="table__col-fourth">
        <span v-if="cartError" class="table__quantity-error" :data-prompt="cartError">
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#warning" />
          </svg>
        </span>
        <div class="table__quantity" :class="{ 'table__quantity--error': cartError }">
          <input
            v-model="quantity"
            class="table__quantity-field"
            type="number"
            min="0"
            placeholder="0"
            @keyup.enter="event => event.target.blur()"
            @change="updateCartItem"
          >
        </div>
        <div class="table__select">
          <vue-multiselect
            v-if="item.product.price && item.product.package_price"
            :id="`format-${item.product.id}`"
            v-model="format"
            :options="[ORDER_FORMAT_PIECE, ORDER_FORMAT_PACKAGE]"
            :multiple="false"
            :searchable="false"
            :allow-empty="false"
            :show-labels="false"
            @update:model-value="updateCartItem"
          >
            <template #singleLabel="{ option }">
              <span class="table__box-text">
                {{ formats[option] }}
              </span>
            </template>
            <template #option="{ option }">
              <span class="table__box-text">
                {{ formats[option] }}
              </span>
            </template>
          </vue-multiselect>
          <div v-else class="select__content">
            <span class="table__box-text">
              {{ formats[format] }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="price" class="table__col table__col--third">
      <div class="table__col-third">
        <div class="table__name">
          {{ total }}
        </div>
        <div class="table__signature">
          {{ vat }}
        </div>
      </div>
    </div>
    <button class="table__basket-remove" data-r-prompt="Удалить из корзины" @click.once="removeCartItem">
      <span class="visually-hidden">Удалить из корзины</span>
      <svg class="s-icon s-icon--largest"><use xlink:href="/img/svg/sprite1.svg#cancel" /></svg>
    </button>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import useProductFields from '@/composables/useProductFields'
import useProductState from '@/composables/useProductState'
import { formatCurrency } from '@/common/formatNumbers'
import { ORDER_FORMAT_PIECE, ORDER_FORMAT_PACKAGE, formats, getFormatDetailsTitle } from '@/common/orderFormats'
import { computed } from 'vue'

export default {
  components: {
    VueMultiselect
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    // TODO: disable cart controls when update amount products in cart
    const { vat, barcodes } = useProductFields(props.item.product)
    const {
      cartError,
      price,
      quantity,
      format,
      updateCartItem,
      removeCartItem
    } = useProductState(props.item.product, props.item.quantity, props.item.format)
    const formatDetailsTitle = computed(() => getFormatDetailsTitle(format.value, props.item.product.package_quantity))
    const total = computed(() => formatCurrency(props.item.total))
    return {
      cartError,
      total,
      price,
      formatDetailsTitle,
      format,
      formats,
      quantity,
      vat,
      barcodes,
      ORDER_FORMAT_PIECE,
      ORDER_FORMAT_PACKAGE,
      updateCartItem,
      removeCartItem
    }
  }
}
</script>
