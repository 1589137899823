<template>
  <div class="table__list">
    <div class="table__col table__col--first">
      <div class="table__col-first">
        <div v-if="item.product.image" class="table__image">
          <img :src="item.product.image" :alt="item.product.name">
        </div>
        <div class="table__content">
          <div class="table__name">
            {{ item.product.name }}
          </div>
          <div class="table__signature">
            {{ barcodes }}
          </div>
        </div>
      </div>
    </div>
    <div class="table__col table__col--second">
      <div class="table__col-second">
        <div class="table__name">
          {{ price }}
        </div>
        <div class="table__signature">
          {{ formatDetailsTitle }}
        </div>
      </div>
    </div>
    <div class="table__col table__col--fourth">
      <div class="table__col-fourth">
        <div class="table__name">
          {{ quantity }} {{ formats[item.format] }}
        </div>
      </div>
    </div>
    <div class="table__col table__col--third">
      <div class="table__col-third">
        <div class="table__name">
          {{ total }}
        </div>
        <div class="table__signature">
          {{ vat }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useProductFields from '@/composables/useProductFields'
import { formatCurrency, formatNumber } from '@/common/formatNumbers'
import { formats, getFormatDetailsTitle } from '@/common/orderFormats'
import { computed } from 'vue'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { vat, barcodes } = useProductFields({ ...props.item.product, vat: props.item.vat })
    const formatDetailsTitle = computed(() => getFormatDetailsTitle(props.item.format, props.item.package_quantity))
    const total = computed(() => formatCurrency(props.item.total))
    const price = computed(() => formatCurrency(props.item.price))
    const quantity = computed(() => formatNumber(props.item.quantity))
    return {
      quantity,
      price,
      total,
      formats,
      formatDetailsTitle,
      vat,
      barcodes
    }
  }
}
</script>
